import { Application } from '@hotwired/stimulus'
import { HeaderController } from './controllers/header_controller'
import { GlobalController } from './controllers/global_controller'
import './swiper'

const app = Application.start()
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  app.debug = true
}

app.register('global', GlobalController)
app.register('header', HeaderController)
