window.addEventListener('DOMContentLoaded', (event) => {
  const sectionTitle = [
    'HARAN', 'ABOUT', 'DOUBLEDAY', 'Cream & Cream', 'COMPANY'
  ]

  const swiper = new Swiper('.swiper-container', { // eslint-disable-line
    direction: 'vertical',
    slidesPerView: 1,
    speed: 600,
    mousewheel: true,
    keyboard: false,
    hashNavigation: {
      replaceState: true,
      watchState: true
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet: function (index, className) {
        return '<div class="' + className + '">' + '<p class="swiper-pagination-title">' + sectionTitle[index] + '</p>' + '</div>'
      }
    }
  })
})
