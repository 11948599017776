import { Controller } from '@hotwired/stimulus'

/**
 * ハンバーガーメニューの開閉を制御する Controller
 */
export class HeaderController extends Controller {
  /**
   * メニューをクリックした時のイベントを設定する
   */
  connect () {
    const navList = this.navlistTarget.querySelectorAll('.header__nav-item')
    this.navListClickRedy(navList)
  }

  /**
   * ハンバーガーメニューの開閉を制御するclassを付け替える
   */
  stateSwitch () {
    if (this.navTarget.classList.contains('header__nav--active')) {
      this.navClose()
    } else if (this.navTarget.classList.contains('header__nav--inactive')) {
      this.navOpen()
    }
  }

  /**
   * ハンバーガーメニューを開く時の動作
   */
  navOpen () {
    this.humbergerTarget.classList.add('header__humberger--active')
    this.navTarget.classList.add('header__nav--active')
    this.navTarget.classList.remove('header__nav--inactive')
    document.addEventListener('mousewheel', this.noScroll, { passive: false })
    document.addEventListener('touchmove', this.noScroll, { passive: false })
  }

  /**
   * ハンバーガーメニューを閉じる時の動作
   */
  navClose () {
    this.humbergerTarget.classList.remove('header__humberger--active')
    this.navTarget.classList.remove('header__nav--active')
    this.navTarget.classList.add('header__nav--inactive')
    document.removeEventListener('mousewheel', this.noScroll, { passive: false })
    document.removeEventListener('touchmove', this.noScroll, { passive: false })
  }

  /**
   * メニューを選択した時にclassを付け替えるためのイベントを準備
   *
   * @param {Array} navList
   */
  navListClickRedy (navList) {
    navList.forEach((element) => {
      element.addEventListener('click', () => {
        this.humbergerTarget.classList.toggle('header__humberger--active')
        this.navTarget.classList.toggle('header__nav--active')
        this.navTarget.classList.toggle('header__nav--inactive')
      })
    })
  }

  /**
   * @param {any} e
   */
  noScroll (e) {
    e.preventDefault()
  }
}

HeaderController.targets = ['header', 'humberger', 'nav', 'navlist']
